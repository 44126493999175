import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import cookie from 'js-cookie';
import queryString from 'query-string';

import { HOSTS } from 'src/configs/hosts';
import { ParsedIdToken, User } from 'src/interfaces/me';

import { ClientError, ClientErrorCode } from './client-error';

export const authKey = '__lunit_ctl_token__';

export const modalityKey = '__lunit_ctl_modality__';

export const getAccessToken = (): string => {
  return cookie.get(authKey) || '';
};

// TODO: [aip-fe-utilities] Extract parseJwt to AIP Utils since it's also used in Case Curator
export const parseJwt = (token: string): ParsedIdToken => {
  const [, base64Url] = token.split('.');
  if (!base64Url) {
    throw new Error('Invalid token');
  }
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const getUserInfoFromToken = (token: string): User => {
  const parsedUser = parseJwt(token);
  const user = {
    ...parsedUser,
    id: parsedUser._id,
    isAdmin: parsedUser.is_admin,
  };

  return user;
};

export enum HTTP_METHODS {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

const handleRequest = <T>(config: InternalAxiosRequestConfig<T>) => {
  const accessToken = getAccessToken();
  if (config.headers && accessToken)
    config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
};

const handleRequestError = (error: AxiosError) => {
  return Promise.reject(error);
};

const handleResponse = (response: AxiosResponse) => {
  if (response.status === 204)
    return {
      ...response,
      data: null, // Set the response data to null for 204 No Content, we need it for enqueue logic
    };
  return response;
};

const handleResponseError = (error: AxiosError) => {
  try {
    if (!error.response || !error.response.status) {
      throw new ClientError({
        code: ClientErrorCode.NETWORK_FAILURE,
        originalError: error,
      });
    }

    if (error.response.status === 401) {
      throw new ClientError({
        code: ClientErrorCode.UNAUTHENTICATED,
        originalError: error,
      });
    }

    if (error.response.status === 403) {
      throw new ClientError({
        code: ClientErrorCode.UNAUTHORIZED,
        originalError: error,
      });
    }

    // this might happen rarely
    const err = error.response as AxiosResponse;
    if (
      error.response.status === 500 &&
      err.data?.message === ClientErrorCode.PAIR_JOB_NOT_MATCHED
    ) {
      throw new ClientError({
        code: ClientErrorCode.PAIR_JOB_NOT_MATCHED,
        originalError: error,
      });
    }

    throw new ClientError({
      code: ClientErrorCode.UNEXPECTED,
      originalError: error,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const paramsSerializer = (params: any): string => {
  const modality = cookie.get(modalityKey);
  return queryString.stringify(
    { ...params, modality },
    {
      skipNull: true,
      skipEmptyString: true,
    }
  );
};

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.params = {};

const authApi = axios.create({
  baseURL: HOSTS.CTL_AUTHENTICATOR_SERVER,
  paramsSerializer,
});

const dataApi = axios.create({
  baseURL: HOSTS.CTL_DATA_SERVER,
  paramsSerializer,
});

const imageApi = axios.create({
  baseURL: HOSTS.CTL_IMAGE_SERVER,
  paramsSerializer,
});

authApi.interceptors.request.use(handleRequest, handleRequestError);
authApi.interceptors.response.use(handleResponse, handleResponseError);
dataApi.interceptors.request.use(handleRequest, handleRequestError);
dataApi.interceptors.response.use(handleResponse, handleResponseError);
imageApi.interceptors.request.use(handleRequest, handleRequestError);
imageApi.interceptors.response.use(handleResponse, handleResponseError);

export { authApi, dataApi, imageApi };
